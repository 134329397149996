import UUID from '../../../utils/UUID'

const mixin = {
  data () {
    return {
      uploadUUID: new UUID(),
      uploadStatus: false,
      uploadFileList: []
    }
  },
  methods: {
    onOneUploadComplete (data) {
      this.uploadFileList.push(data)
    },
    onUploadComplete () {
      this.uploadStatus = false
    },
    onBeforeUpload () {
      this.uploadStatus = true
    },
    onError (up, err, errTip) {
      this.uploadStatus = false
      this.$message.error('上传错误：err is [' + err + '] errTip is [' + errTip + ']')
    }
  }
}

export default mixin