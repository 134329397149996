<template>
  <div>
    <local-upload
      ref="upload"
      :pickfiles="'pickfiles-files-'+uploadUUID"
      :container="'container-files-'+uploadUUID"
      :documentDirId="-1"
      :autoStart="true"
      :multiSelection="false"
      @onOneUploadComplete="onOneUploadComplete"
      @onUploadComplete="onUploadComplete"
      @onBeforeUpload="onBeforeUpload"
      @onFileRemove="onFileRemove"
      @onError="onError">
      <div slot="uploader">
        <div :id="'container-files-'+uploadUUID">
          <a-button type="dashed"
                    class="m-r-12 cursor-pointer"
                    icon="upload"
                    :loading="uploadStatus"
                    :id="'pickfiles-files-'+uploadUUID">选择文件
          </a-button>
          {{tips}}
        </div>
      </div>
    </local-upload>
    <ul class="earl-upload-list w-450" :class="uploadFileList.length === 0?'m-t-12':''"
        v-if="currentDefaultValue && this.uploadFileList.length === 0">
      <li class="earl-upload-list-file">
        <a-row>
          <a-col span="23">
            <span><a-icon :type="format(currentDefaultValue.newName)"/>&nbsp;{{currentDefaultValue.name}}&nbsp;{{humanReadableUnits(currentDefaultValue.fileSize)}}</span>
          </a-col>
          <a-col span="1">
            <a-icon type="close" class="earl-upload-list-remove" @click.native="removeFile()"/>
          </a-col>
        </a-row>
      </li>
    </ul>
  </div>
</template>

<script>

  import uploadCommon from '../../components/LocalUpload/mixins/upload-common'
  import { fileIconFormat, humanReadableUnits } from '../../utils/utils'
  import LocalUpload from '../../components/LocalUpload'

  export default {
    name: 'SingleSimpleUpload',
    mixins: [uploadCommon],
    props: {
      value: {
        type: Number
      },
      // 一般用于编辑，传入服务端文件数据结构
      defaultValue: {
        type: Object
      },
      tips: {
        type: String,
        default: '支持文件拓展名:.doc .docx .pdf'
      }
    },
    data () {
      return {
        format: fileIconFormat,
        humanReadableUnits: humanReadableUnits,
        currentValue: this.value,
        currentDefaultValue: this.defaultValue
      }
    },
    watch: {
      value (val) {
        this.currentValue = val
      },
      defaultValue (val) {
        this.currentDefaultValue = val
      }
    },
    methods: {
      onBeforeUpload () {
        this.uploadFileList = []
        this.uploadStatus = true
      },
      onUploadComplete () {
        this.uploadStatus = false
        if (this.uploadFileList.length > 0) {
          this.currentValue = this.uploadFileList[0].id
          this.change()
          this.currentDefaultValue = this.uploadFileList[0]
        }
      },
      removeFile () {
        this.currentDefaultValue = null
        this.currentValue = null
        this.change()
      },
      onOneUploadComplete (body) {
        this.$emit('change', body)
      },
      change () {
        this.$emit('change', this.currentValue)
        this.$emit('input', this.currentValue)
      },
      // 新上传文件被移除
      onFileRemove () {
        this.uploadFileList = []
        this.removeFile()
      }
    },
    components: { LocalUpload }
  }
</script>