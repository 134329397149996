const releaseArticleVO = function () {
  return {
    article: {
      id: '',
      content: '',
      contentMd: '',
      ifTop: false,
      mainPhoto: null,
      name: '',
      releaseTime: '',
      sketch: '',
      sorter: 0,
      sources: '',
      staticCode: '',
      status: ' 草稿',
      fullName: '',
      parentId: null,
      parentIds: null,
      resourceId: 0,
      creater: '',
      dateCreated: '',
      lastUpdated: '',
      user: {
        id: ''
      }
    },
    tagIds: [],
    typeIds: []
  }

}
export default releaseArticleVO
